import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import {styleMap} from 'lit/directives/style-map';
import '@iconify/iconify';
import {Day, WeatherApiResponse, weatherIcon} from '../apis/WeatherApi';

const API_KEY = '13d7f832aa2d4a218e0213836212402';
const LOCATION = '53.552886951081994, 10.023040568074409';
const WEATER_API_URL = `https://api.weatherapi.com/v1/forecast.json?key=${API_KEY}&q=${LOCATION}&aqi=no&alerts=no&lang=de`;

@customElement('weather-current')
export class WeatherCurrent extends LitElement {
  render() {
    return html`${until(this.getWeather(), html`?`)}`;
  }

  createRenderRoot() {
    return this;
  }

  async getWeather() {
    let res: WeatherApiResponse = await fetch(WEATER_API_URL).then((res) =>
      res.json()
    );

    const forecast: Day = res.forecast.forecastday[0].day;


    return html`
      <div class="flex font-bold text-xl gap-2">
        <div>${res.current.temp_c}<small class="text-xs">°C</small></div>
        <div class="${res.current.wind_kph >= 57 ? 'text-red' : ''}">${res.current.wind_kph.toFixed(0)}<small class="text-xs">km/h</small></div>
        <div class="flex-grow text-center">
          ${forecast.condition.text}
         <!-- ${res.current.condition.icon}
          <i
            class="h-10 w-10 iconify"
            data-icon="wi:${weatherIcon(
              res.current.condition.code,
              res.current.is_day === 1
            )}"
          ></i>-->
        </div>
        <div>${forecast.maxtemp_c.toFixed(0)}<small class="text-xs">°C</small></div>
        <div class="${forecast.uv > 5 ? 'text-red' : ''}">${forecast.uv}<small class="text-xs">UV</small></div>
        <div class="${forecast.totalprecip_mm > 2 ? 'text-red' : ''}">${forecast.totalprecip_mm.toFixed(1)}<small class="text-xs">mm</small></div>
        
      </div>
    `;
  }
}

function windDirection(dir: string) {
  let d = dir.toLowerCase().substring(0, 2);

  switch (d) {
    case 'wn':
      return 'nw';
    case 'en':
      return 'ne';
    case 'ws':
      return 'sw';
    case 'es':
      return 'se';
  }

  return d;
}

declare global {
  interface HTMLElementTagNameMap {
    'weather-current': WeatherCurrent;
  }
}
